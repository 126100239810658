import {
  type SupabaseClient,
  type Session as SupabaseSession,
} from "@supabase/supabase-js";
import { atom } from "jotai";

export const supabaseClientAtom = atom(undefined as SupabaseClient | undefined);
export const supabaseSessionAtom = atom(
  "loading" as "loading" | SupabaseSession | null,
);

export const downloadURL = async (
  supabase: SupabaseClient | undefined,
  url: string,
  fileName: string,
  bucket = "uploads",
) => {
  if (!supabase) {
    return;
  }

  const { data, error } = await supabase.storage.from(bucket).download(url);

  if (!data || error) {
    console.error("Failed to load file");
  } else {
    const tempUrl = window.URL.createObjectURL(data);
    setTimeout(() => {
      window.URL.revokeObjectURL(tempUrl);
    }, 1000);

    const a = document.createElement("a");
    a.href = tempUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.click();
    a.remove();
  }
};
