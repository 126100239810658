import { type ReactNode } from "react";
import type React from "react";
import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { twMerge } from "tailwind-merge";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title: string;
  id?: string;
  actions?: React.ReactNode;
  className?: string;
}

const Modal: React.FC<ModalProps> = ({
  id,
  open,
  onClose,
  children,
  title,
  actions,
  className,
}) => {
  const modalRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (open && modalRef.current) {
      modalRef.current.showModal();
      trapFocus(modalRef.current);
      announce("Modal opened");
    } else {
      modalRef.current?.close();
      announce("Modal closed");
    }
  }, [open]);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    if (open) {
      document.addEventListener("keydown", handleEscape);
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [open, onClose]);

  const handleBackdropClick = (e: React.MouseEvent<HTMLDialogElement>) => {
    if (e.target === modalRef.current) {
      onClose();
    }
  };

  const announce = (message: string) => {
    const announcement = document.createElement("div");
    announcement.setAttribute("role", "alert");
    announcement.style.position = "absolute";
    announcement.style.clip = "rect(0 0 0 0)";
    announcement.style.height = "1px";
    announcement.style.margin = "-1px";
    announcement.style.overflow = "hidden";
    announcement.style.padding = "0";
    announcement.style.width = "1px";
    announcement.textContent = message;
    document.body.appendChild(announcement);
    setTimeout(() => {
      document.body.removeChild(announcement);
    }, 1000);
  };

  const trapFocus = (element: HTMLDialogElement) => {
    const focusableEls = element.querySelectorAll<HTMLElement>(
      'a[href], button, textarea, input, select, [tabindex]:not([tabindex="-1"])',
    );
    const firstFocusableEl = focusableEls[0];
    const lastFocusableEl = focusableEls[focusableEls.length - 1];

    element.addEventListener("keydown", (e) => {
      const isTabPressed = e.key === "Tab" || e.keyCode === 9;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    });

    element.focus();
  };

  return createPortal(
    <dialog
      id={id}
      ref={modalRef}
      className={twMerge("modal", open && "modal-open")}
      onClick={handleBackdropClick}
      aria-labelledby="modal-title"
      aria-describedby="modal-content"
    >
      <div
        className={twMerge(
          "modal-box flex h-min max-w-xl flex-col justify-center overflow-x-hidden bg-white p-0 text-gray-900",
          className,
        )}
      >
        <div className="modal-header border-b border-gray-100 px-5 pb-4 pt-7">
          <h3 id="modal-title" className="text-base font-bold">
            {title}
          </h3>
        </div>
        <div
          id="modal-content"
          className="modal-content flex flex-1 flex-col gap-4 overflow-y-auto px-5 py-4"
          style={{ maxHeight: "calc(100% - 132px)" }}
        >
          {children}
        </div>
        <div className="modal-footer flex items-center justify-end gap-3 bg-gray-100 px-4 py-3">
          {actions}
          <button
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
            className="rounded-md border-none bg-white px-2.5 py-1.5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </dialog>,
    document.body,
  );
};

export default Modal;
