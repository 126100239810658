import AuthLayout from "@/layouts/AuthLayout";
import MainLayout from "@/layouts/MainLayout";
import ChambersDetailView from "@/pages/Chambers/ChambersDetail";
import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { type AppRouteObject } from "router/interface";

// Lazy load the routes to improve performance
const SettingsPage = lazy(() => import("@/pages/Settings"));
const FirmSettingsPage = lazy(() => import("@/pages/FirmSettings"));
const ChatPage = lazy(() => import("@/pages/Chat"));
const DocumentUpload = lazy(() => import("@/pages/DocumentUpload"));
const DocumentPreview = lazy(() => import("@/pages/DocumentPreview"));
const Page404 = lazy(() => import("@/pages/Page404"));
const Chambers = lazy(() => import("@/pages/Chambers"));
const Questionnaires = lazy(() => import("@/pages/Questionnaires"));
const QuestionnaireDetail = lazy(
  () => import("@/pages/Questionnaires/QuestionnaireDetail"),
);
const Urls = lazy(() => import("@/pages/Urls"));
const Recovery = lazy(() => import("@/pages/auth/Recovery"));
const Reset = lazy(() => import("@/pages/auth/Reset"));
const MagicLink = lazy(() => import("@/pages/auth/MagicLink"));
const SignIn = lazy(() => import("@/pages/auth/SignIn"));
const SignUp = lazy(() => import("@/pages/auth/SignUp"));
const SignInWithMagicLink = lazy(
  () => import("@/pages/auth/SignInWithMagicLink"),
);

const routes: AppRouteObject[] = [
  {
    path: "/",
    element: <Navigate to="/chat" replace />,
  },
  {
    path: "/chat",
    isProtected: true,
    element: <MainLayout />,
    children: [
      { index: true, element: <ChatPage /> },
      { path: ":chatId", element: <ChatPage /> },
    ],
  },
  {
    path: "/urls",
    isProtected: true,
    element: <MainLayout />,
    children: [{ index: true, element: <Urls /> }],
  },
  {
    path: "/settings",
    isProtected: true,
    element: <MainLayout />,
    children: [{ index: true, element: <SettingsPage /> }],
  },
  {
    path: "/firm-settings",
    isProtected: true,
    element: <MainLayout />,
    children: [{ index: true, element: <FirmSettingsPage /> }],
  },
  {
    path: "/questionnaires",
    isProtected: true,
    element: <MainLayout />,
    children: [
      { index: true, element: <Questionnaires /> },
      { path: ":documentId", element: <QuestionnaireDetail /> },
    ],
  },
  {
    path: "/documents",
    isProtected: true,
    element: <MainLayout />,
    children: [
      { index: true, element: <DocumentUpload /> },
      { path: ":embeddingId", element: <DocumentPreview /> },
    ],
  },
  {
    path: "/chambers",
    isProtected: true,
    element: <MainLayout />,
    children: [
      { index: true, element: <Chambers /> },
      { path: ":submissionId", element: <ChambersDetailView /> },
      {
        path: ":submissionId/matter/:matterId",
        element: <ChambersDetailView />,
      },
    ],
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      { path: "sign-in", element: <SignIn /> },
      { path: "sign-up", element: <SignUp /> },
      { path: "recovery", element: <Recovery /> },
      { path: "set-password", element: <Reset /> },
      { path: "sign-in-with-magic-link", element: <SignInWithMagicLink /> },
      { path: "magic-link", element: <MagicLink /> },
    ],
  },
  {
    path: "/*",
    element: <Page404 />,
  },
];

export default routes;
