import Spinner from "@/components/Spinner";
import { Suspense } from "react";
import { useOutlet } from "react-router-dom";

const SuspenseOutlet = () => {
  const outlet = useOutlet();

  return <Suspense fallback={<Spinner />}>{outlet}</Suspense>;
};

export default SuspenseOutlet;
