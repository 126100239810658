import { queryClient, queryKeys } from "@/client";
import { useAuth } from "@/contexts/AuthHook";
import { type ChambersSubmissionFull } from "@precedent/db-types/src/schema";
import { type SupabaseClient } from "@supabase/supabase-js";
import { useMutation } from "@tanstack/react-query";
import groupBy from "lodash/groupBy";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import MattersListTab from "./MatterListTab";

export default function ChambersDetailMatterList({
  submission,
  ...rest
}: {
  submission: ChambersSubmissionFull;
}) {
  const [publicTab, setPublicTab] = useState(true);
  const [matters, setMatters] = useState(submission.matters);
  const [dropIndicator, setDropIndicator] = useState<{
    index: number;
    type: string;
  } | null>(null);

  const { enqueueSnackbar } = useSnackbar();
  const { submissionId } = useParams();
  const { supabase } = useAuth();

  useEffect(() => {
    setMatters(submission.matters);
  }, [submission.matters]);

  const updateMatterOrderMutation = useMutation({
    mutationFn: async (
      updatedMatters: ChambersSubmissionFull["matters"],
    ): Promise<null> => {
      const supabaseClient = supabase as SupabaseClient;

      const updates = updatedMatters.map((matter) => ({
        id: matter.id,
        included_order: matter.included_order,
        potential_order: matter.potential_order,
      }));

      const updatePromises = updates.map((update) =>
        supabaseClient
          .from("chambers_matter")
          .update({
            included_order: update.included_order,
            potential_order: update.potential_order,
          })
          .eq("id", update.id),
      );

      const results = await Promise.all(updatePromises);

      results.forEach(({ error }) => {
        if (error) throw new Error(error.message);
      });

      return null;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: queryKeys.chambersSubmission(submissionId as string),
      });
      enqueueSnackbar("Matters updated successfully", { variant: "success" });
    },
  });

  const publicMatters = groupBy(matters, "is_public");
  const sortIncluded = (matters: ChambersSubmissionFull["matters"]) =>
    (matters ?? [])
      .filter((m) => m.included_order !== null)
      .sort((a, b) => a.included_order! - b.included_order!);
  const sortPotential = (matters: ChambersSubmissionFull["matters"]) =>
    (matters ?? [])
      .filter((m) => m.potential_order !== null)
      .sort((a, b) => a.potential_order! - b.potential_order!);

  const publicIncludedMatters = sortIncluded(publicMatters.true);
  const publicPotentialMatters = sortPotential(publicMatters.true);
  const confidentialIncludedMatters = sortIncluded(publicMatters.false);
  const confidentialPotentialMatters = sortPotential(publicMatters.false);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            <button
              key={"public"}
              onClick={() => {
                setPublicTab(!publicTab);
              }}
              className={twMerge(
                publicTab
                  ? "border-blue-500 text-blue-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium",
              )}
              aria-current={publicTab ? "page" : undefined}
            >
              Public
            </button>
            <button
              key={"confidential"}
              onClick={() => {
                setPublicTab(!publicTab);
              }}
              className={twMerge(
                !publicTab
                  ? "border-blue-500 text-blue-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium",
              )}
              aria-current={!publicTab ? "page" : undefined}
            >
              Confidential
            </button>
          </nav>
        </div>
      </div>

      <MattersListTab
        includedMatters={
          publicTab ? publicIncludedMatters : confidentialIncludedMatters
        }
        potentialMatters={
          publicTab ? publicPotentialMatters : confidentialPotentialMatters
        }
        setMatters={setMatters}
        dropIndicator={dropIndicator}
        setDropIndicator={(newIndicator: typeof dropIndicator) => {
          setDropIndicator(newIndicator);
        }}
        updateMatterOrderMutation={updateMatterOrderMutation.mutate}
        publicTab={publicTab}
        {...rest}
      />
    </DndProvider>
  );
}
