import { queryClient, queryKeys } from "@/client";
import { useAuth } from "@/contexts/AuthHook";
import { type ChambersSubmissionFull } from "@precedent/db-types/src/schema";
import { handleResponse } from "@precedent/shared-util";
import { type SupabaseClient } from "@supabase/supabase-js";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import type React from "react";
import { useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { FaEllipsisH } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import StatusButton from "./StatusButton";

const ItemType = "Matter";

const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
};

interface MatterListItemProps {
  matter: ChambersSubmissionFull["matters"][number];
  index: number;
  type: string;
  setDropIndicator: (indicator: { index: number; type: string } | null) => void;
}

const MatterListItem: React.FC<MatterListItemProps> = ({
  matter,
  index,
  type,
  setDropIndicator,
}) => {
  const { supabase } = useAuth();
  const { submissionId, matterId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [menuOpen, setMenuOpen] = useState(false);
  const maxClientNameLength = 40;

  const selectedMatterId = matterId ? parseInt(matterId) : null;

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index, type },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (!isDragging) {
      setDropIndicator(null);
    }
  }, [isDragging, setDropIndicator]);

  const toggleConfidentialMutation = useMutation({
    mutationFn: async ({
      id,
      newIsPublic,
    }: {
      id: ChambersSubmissionFull["matters"][number]["id"];
      newIsPublic: ChambersSubmissionFull["matters"][number]["is_public"];
    }): Promise<null> =>
      await (supabase as SupabaseClient)
        .from("chambers_matter")
        .update({ is_public: newIsPublic })
        .eq("id", id)
        .then(handleResponse<null>),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.chambersSubmission(submissionId as string),
      });
      enqueueSnackbar("Matter moved successfully", { variant: "success" });
    },
  });

  const deleteMatterMutation = useMutation({
    mutationFn: async ({
      id,
    }: {
      id: ChambersSubmissionFull["matters"][number]["id"];
    }): Promise<null> =>
      await (supabase as SupabaseClient)
        .from("chambers_matter")
        .delete()
        .eq("id", id)
        .then(handleResponse<null>),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.chambersSubmission(submissionId as string),
      });
      enqueueSnackbar("Matter deleted successfully", { variant: "success" });
    },
  });

  return (
    <div
      ref={drag}
      title={matter.name}
      className={twMerge(
        "flex w-full cursor-pointer items-center justify-between gap-x-6 rounded border border-transparent px-2 py-5",
        selectedMatterId === matter.id && "border-blue-500",
        isDragging && "opacity-50",
      )}
      onClick={() => {
        navigate(`/chambers/${submissionId}/matter/${matter.id}`);
      }}
    >
      <div className="min-w-0 flex-1">
        <div className="flex flex-col gap-y-1">
          <p className="truncate text-sm font-semibold leading-6 text-gray-900">
            {matter.name}
          </p>

          <div className="flex items-center justify-between gap-x-2 text-xs leading-5 text-gray-500">
            <p className="truncate">
              {matter.client_name
                ? truncateText(matter.client_name, maxClientNameLength)
                : ""}
            </p>
            <StatusButton
              type="matter"
              currentStatus={matter.approval_status}
              id={matter.id}
              submissionId={submissionId}
            />
          </div>
          <div className="group flex items-center justify-between">
            <p className="text-xs leading-5 text-gray-500">
              {matter.lead_partner_name}
            </p>
            <DropdownMenu
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              isPublic={!!matter.is_public}
              toggleConfidential={() => {
                toggleConfidentialMutation.mutate({
                  id: matter.id,
                  newIsPublic: !matter.is_public,
                });
              }}
              deleteMatter={() => {
                deleteMatterMutation.mutate({ id: matter.id });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatterListItem;

const DropdownMenu = ({
  menuOpen,
  setMenuOpen,
  isPublic,
  toggleConfidential,
  deleteMatter,
}: {
  menuOpen: boolean;
  setMenuOpen: (newValue: boolean) => void;
  isPublic: boolean;
  toggleConfidential: () => void;
  deleteMatter: () => void;
}) => {
  const navigate = useNavigate();
  const { submissionId } = useParams();

  const buttonRef = useRef<any>(null);
  const dropdownRef = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (
      buttonRef.current &&
      dropdownRef.current &&
      !buttonRef.current.contains(event.target) &&
      !dropdownRef.current.contains(event.target)
    ) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setMenuOpen(false);
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  return (
    <div className="dropdown relative">
      <button
        ref={buttonRef}
        className="btn btn-ghost btn-xs opacity-0 hover:bg-gray-100 group-hover:opacity-100"
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      >
        <FaEllipsisH />
      </button>

      {menuOpen && (
        <ul
          tabIndex={0}
          className="menu absolute right-0 top-7 z-[1] w-min rounded-box rounded-md border border-gray-200 bg-base-100 p-0 shadow"
          ref={dropdownRef}
        >
          <li>
            <p
              className="w-full whitespace-nowrap rounded-none rounded-t-md text-gray-900 hover:bg-gray-100"
              onClick={(e) => {
                e.stopPropagation();
                toggleConfidential();
                setMenuOpen(false);
                navigate(`/chambers/${submissionId}`);
              }}
            >
              {`Move to ${isPublic ? '"Confidential"' : '"Public"'}`}
            </p>
          </li>
          <li>
            <p
              className="w-full whitespace-nowrap rounded-none rounded-b-md text-red-600 hover:bg-red-100"
              onClick={(e) => {
                e.stopPropagation();
                deleteMatter();
                setMenuOpen(false);
                navigate(`/chambers/${submissionId}`);
              }}
            >
              Delete Matter
            </p>
          </li>
        </ul>
      )}
    </div>
  );
};
