import { azureFunctionsServer, queryClient, queryKeys } from "@/client";
import { useAuth } from "@/contexts/AuthHook";
import { type ChambersMatter as ChambersMatterT } from "@precedent/db-types/src/schema";
import { type SupabaseClient } from "@supabase/supabase-js";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Modal from "../Modal";

const NewMatterModal = ({
  open,
  setModalOpen,
  isPublic,
  order,
}: {
  open: boolean;
  setModalOpen: (newState: boolean) => void;
  isPublic: boolean;
  order: number;
}) => {
  const { supabase, firmId, userId, session, firmName } = useAuth();
  const { submissionId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [clientName, setClientName] = useState("");
  const navigate = useNavigate();

  const createMatterMutation = useMutation({
    mutationFn: async (): Promise<ChambersMatterT | null> => {
      const { data, error } = await (supabase as SupabaseClient)
        .from("chambers_matter")
        .insert({
          name,
          client_name: clientName,
          created_by: userId,
          approval_status: "Awaiting Approval",
          chambers_submission_id: submissionId,
          firm_id: firmId,
          is_public: isPublic,
          included_order: order,
          generation_status: "GENERATING",
        })
        .select("*")
        .single();

      if (error) {
        console.error("Error inserting matter:", error);
        return null;
      }

      return data;
    },
    onSuccess: async (newMatter, ...rest) => {
      if (!newMatter) {
        return;
      }

      const matterId = newMatter.id;
      navigate(`/chambers/${submissionId}/matter/${matterId}`);

      if (session !== "loading" && session !== null && matterId) {
        await azureFunctionsServer
          .auth(`Bearer ${session.access_token}`)
          .url("/chambers-matter-generate")
          .post({
            matterName: name,
            clientName,
            submissionId,
            userId,
            firmId,
            firmName,
            matterId,
          })
          .text();
      }
      void queryClient.refetchQueries({
        queryKey: queryKeys.chambersSubmission(submissionId as string),
      });

      enqueueSnackbar("Matter created successfully", {
        variant: "success",
      });

      setName("");
      setClientName("");
      setModalOpen(false);
    },
  });

  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setModalOpen(!open);
        }}
        className="text-sm font-medium text-blue-600 hover:text-blue-500"
        title="Create a new Matter"
      >
        + Create New
      </button>

      <Modal
        id="new_matter_modal"
        open={open}
        onClose={() => {
          setModalOpen(false);
          setName("");
          setClientName("");
        }}
        title="Create New Matter"
        actions={
          <button
            onClick={(e) => {
              e.preventDefault();
              createMatterMutation.mutate();
            }}
            type="button"
            className="rounded-md border-none bg-white px-2.5 py-1.5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none"
          >
            Create
          </button>
        }
      >
        <div className="flex gap-4">
          <div className="flex w-full flex-1 flex-col gap-2">
            <label htmlFor="name" className="text-xs font-medium text-gray-900">
              Matter Name
            </label>
            <div>
              <input
                type="text"
                name="name"
                id="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                autoComplete="name"
                className="input-sm w-full rounded-md border-0 px-3 py-1.5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400"
              />
            </div>
          </div>
          <div className="flex w-full flex-1 flex-col gap-2">
            <label
              htmlFor="clientName"
              className="text-xs font-medium text-gray-900"
            >
              Client Name
            </label>
            <div>
              <input
                type="text"
                name="clientName"
                id="clientName"
                value={clientName}
                onChange={(e) => {
                  setClientName(e.target.value);
                }}
                className="input-sm w-full rounded-md border-0 px-3 py-1.5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400"
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NewMatterModal;
