import { queryClient, queryKeys } from "@/client";
import { useAuth } from "@/contexts/AuthHook";
import { type ChambersSubmissionFull } from "@precedent/db-types/src/schema";
import { handleResponse } from "@precedent/shared-util";
import { type SupabaseClient } from "@supabase/supabase-js";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

type FormValues = {
  client_name: string;
  value: string;
  lead_partner_name: string;
  other_team_members: string;
  other_firms: string;
  date_of_completion: string;
  other_info: string;
  cross_border: string;
};

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
const getInitialFormValues = (
  matter: ChambersSubmissionFull["matters"][number] | undefined,
): FormValues => ({
  client_name: matter?.client_name || "",
  value: matter?.value || "",
  lead_partner_name: matter?.lead_partner_name || "",
  other_team_members: matter?.other_team_members || "",
  other_firms: matter?.other_firms || "",
  date_of_completion: matter?.date_of_completion || "",
  other_info: matter?.other_info || "",
  cross_border: matter?.cross_border || "",
});
/* eslint-enable @typescript-eslint/prefer-nullish-coalescing */

export default function ChambersDetailForm({
  submission,
  selectedMatterId,
}: {
  submission: ChambersSubmissionFull;
  selectedMatterId: ChambersSubmissionFull["matters"][number]["id"] | null;
}) {
  const { supabase } = useAuth();
  const { submissionId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const selectedMatter = submission.matters.find(
    (matter) => matter.id === selectedMatterId,
  );

  const [formValues, setFormValues] = useState<FormValues>(
    getInitialFormValues(selectedMatter),
  );

  useEffect(() => {
    const newMatter = submission.matters.find(
      (matter) => matter.id === selectedMatterId,
    );

    setFormValues(getInitialFormValues(newMatter));
  }, [selectedMatterId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const updateChambersMatterMutation = useMutation({
    mutationFn: async (): Promise<null> => {
      const updatedFields = Object.keys(formValues).reduce<Partial<FormValues>>(
        (acc, key) => {
          const value = formValues[key as keyof FormValues];
          if (value !== "" && value !== null) {
            (acc as any)[key] = value; // Use type assertion to avoid TypeScript error
          }
          return acc;
        },
        {},
      );

      return await (supabase as SupabaseClient)
        .from("chambers_matter")
        .update(updatedFields)
        .eq("id", selectedMatterId)
        .then(handleResponse<null>);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: queryKeys.chambersSubmission(submissionId as string),
      });

      enqueueSnackbar("Matter updated successfully", {
        variant: "success",
      });
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        updateChambersMatterMutation.mutate();
      }}
    >
      <div className="space-y-12">
        <div className="flex flex-col gap-6">
          <div className="flex w-full flex-1 flex-col gap-2">
            <label
              htmlFor="client_name"
              className="text-xs font-medium text-gray-900"
            >
              Client Name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="client_name"
                id="client_name"
                value={formValues.client_name}
                onChange={handleChange}
                autoComplete="client_name"
                className="input-sm w-full rounded-md border-0 px-3 py-1.5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400"
              />
            </div>
          </div>

          <div className="flex w-full flex-1 flex-col gap-2">
            <label
              htmlFor="value"
              className="text-xs font-medium text-gray-900"
            >
              Value
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="value"
                id="value"
                value={formValues.value}
                onChange={handleChange}
                autoComplete="value"
                className="input-sm w-full rounded-md border-0 px-3 py-1.5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400"
              />
            </div>
          </div>

          <div className="flex w-full flex-1 flex-col gap-2">
            <label
              htmlFor="lead_partner_name"
              className="text-xs font-medium text-gray-900"
            >
              Lead Partner
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="lead_partner_name"
                id="lead_partner_name"
                value={formValues.lead_partner_name}
                onChange={handleChange}
                autoComplete="lead-partner-name"
                className="input-sm w-full rounded-md border-0 px-3 py-1.5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400"
              />
            </div>
          </div>

          <div className="flex w-full flex-1 flex-col gap-2">
            <label
              htmlFor="other_team_members"
              className="text-xs font-medium text-gray-900"
            >
              Other Team Members
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="other_team_members"
                id="other_team_members"
                value={formValues.other_team_members}
                onChange={handleChange}
                autoComplete="other-team-members"
                className="input-sm w-full rounded-md border-0 px-3 py-1.5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400"
              />
            </div>
          </div>

          <div className="flex w-full flex-1 flex-col gap-2">
            <label
              htmlFor="other_firms"
              className="text-xs font-medium text-gray-900"
            >
              Other Firms Advising
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="other_firms"
                id="other_firms"
                value={formValues.other_firms}
                onChange={handleChange}
                autoComplete="other-firms"
                className="input-sm w-full rounded-md border-0 px-3 py-1.5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400"
              />
            </div>
          </div>

          <div className="flex w-full flex-1 flex-col gap-2">
            <label
              htmlFor="date_of_completion"
              className="text-xs font-medium text-gray-900"
            >
              Date of Completion
            </label>
            <div className="mt-2">
              <input
                type="date"
                name="date_of_completion"
                id="date_of_completion"
                value={formValues.date_of_completion}
                onChange={handleChange}
                autoComplete="date-of-completion"
                className="input-sm w-full rounded-md border-0 px-3 py-1.5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400"
              />
            </div>
          </div>

          <div className="flex w-full flex-1 flex-col gap-2">
            <label
              htmlFor="other_info"
              className="text-xs font-medium text-gray-900"
            >
              Other Info (e.g., press release)
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="other_info"
                id="other_info"
                value={formValues.other_info}
                onChange={handleChange}
                autoComplete="other-info"
                className="input-sm w-full rounded-md border-0 px-3 py-1.5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400"
              />
            </div>
          </div>

          <div className="flex w-full flex-1 flex-col gap-2">
            <label
              htmlFor="cross_border"
              className="text-xs font-medium text-gray-900"
            >
              Cross Border?
            </label>
            <div className="mt-2">
              <input
                type="checkbox"
                name="cross_border"
                id="cross_border"
                checked={/yes/i.test(formValues.cross_border)}
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    cross_border: e.target.checked ? "Yes" : "No",
                  });
                }}
                className="block rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
        <div className="mt-6 flex justify-end">
          <button
            type="submit"
            className="rounded-md border-none px-2.5 py-1.5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-blue-500/10 focus:outline-none"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
