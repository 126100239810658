import { queryClient, queryKeys } from "@/client";
import { useAuth } from "@/contexts/AuthHook";
import { handleResponse } from "@precedent/shared-util";
import { type SupabaseClient } from "@supabase/supabase-js";
import { useMutation, useQuery } from "@tanstack/react-query";
import keyBy from "lodash/keyBy";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from "react-icons/md";
import { useParams } from "react-router-dom";

import Modal from "../Modal";

const SearchMatterModal = ({
  open,
  setModalOpen,
  isPublic,
  order,
}: {
  open: boolean;
  setModalOpen: (newState: boolean) => void;
  isPublic: boolean;
  order: number;
}) => {
  const { supabase, userId, firmId } = useAuth();
  const { submissionId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [clientName, setClientName] = useState("");
  const [practiceAreaName, setPracticeAreaName] = useState("");
  const [selectedMatters, setSelectedMatters] = useState<Set<number>>(
    new Set(),
  );

  const { data: matters, refetch: refetchMatters } = useQuery({
    queryKey: ["searchMatters", name, clientName, practiceAreaName],
    queryFn: async () => {
      const { data, error } = await (supabase as SupabaseClient)
        .from("chambers_matter")
        .select(
          "*, submission: chambers_submission_id!inner ( schedule: chambers_schedule_id ( year, canonical: chambers_canonical_practice_area_id ( practice_area_name, guide, location )))",
        )
        .ilike("name", `%${name}%`)
        .ilike("client_name", `%${clientName}%`);

      if (error) {
        throw new Error(error.message);
      }

      // Transform the data to fit the ChambersSubmission type
      let transformedData = data.map((matter) => {
        const { submission, ...rest } = matter;

        return {
          flatSubmission: {
            year: submission.schedule.year,
            ...submission.schedule.canonical,
          },
          ...rest,
        };
      });

      if (practiceAreaName) {
        transformedData = transformedData.filter((matter) =>
          matter.flatSubmission.practice_area_name
            .toLowerCase()
            .includes(practiceAreaName),
        );
      }

      return keyBy(transformedData, "id");
    },
    enabled: false,
  });

  useEffect(() => {
    if (name || clientName || practiceAreaName) {
      void refetchMatters();
    }
  }, [name, clientName, practiceAreaName]);

  const addMattersToSubmissionMutation = useMutation({
    mutationFn: async () => {
      if (matters) {
        await (supabase as SupabaseClient)
          .from("chambers_matter")
          .insert(
            [...selectedMatters].map((matterId, index) => {
              const { id, flatSubmission, ...matter } = matters[matterId];

              return {
                ...matter,
                chambers_submission_id: submissionId,
                created_by: userId,
                approval_status: "Awaiting Approval",
                firm_id: firmId,
                is_public: isPublic,
                included_order: order + index,
              };
            }),
          )
          .then(handleResponse<null>);
      }
    },
    onSuccess: async () => {
      void queryClient.invalidateQueries({
        queryKey: queryKeys.chambersSubmission(submissionId as string),
      });

      enqueueSnackbar("Matters added to submission successfully", {
        variant: "success",
      });

      setSelectedMatters(new Set());
      setName("");
      setClientName("");
      setPracticeAreaName("");
      setModalOpen(false);
    },
  });

  const handleSelectMatter = (matterId: number) => {
    const updatedSet = new Set(selectedMatters);
    if (updatedSet.has(matterId)) {
      updatedSet.delete(matterId);
    } else {
      updatedSet.add(matterId);
    }
    setSelectedMatters(updatedSet);
  };

  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setModalOpen(!open);
        }}
        className="text-sm font-medium text-blue-600 hover:text-blue-500"
        title="Create a new Matter"
      >
        + Add Existing
      </button>

      <Modal
        id="search_matter_modal"
        className="max-w-3xl"
        open={open}
        onClose={() => {
          setModalOpen(false);
          setName("");
          setClientName("");
          setPracticeAreaName("");
        }}
        title="Add Existing Matter"
        actions={
          <button
            onClick={(e) => {
              e.preventDefault();
              addMattersToSubmissionMutation.mutate();
            }}
            type="button"
            className="rounded-md border-none bg-white px-2.5 py-1.5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none"
          >
            Add Selected Matters
          </button>
        }
      >
        <div className="flex gap-4">
          <div className="flex w-full flex-1 flex-col gap-2">
            <label htmlFor="name" className="text-xs font-medium text-gray-900">
              Matter Name
            </label>
            <div className="mt-2">
              <input
                placeholder="Search by name"
                type="text"
                name="name"
                id="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                autoComplete="name"
                className="input-sm w-full rounded-md border-0 px-3 py-1.5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400"
              />
            </div>
          </div>
          <div className="flex w-full flex-1 flex-col gap-2">
            <label
              htmlFor="clientName"
              className="text-xs font-medium text-gray-900"
            >
              Client Name
            </label>
            <div className="mt-2">
              <input
                placeholder="Search by client name"
                type="text"
                name="clientName"
                id="clientName"
                value={clientName}
                onChange={(e) => {
                  setClientName(e.target.value);
                }}
                autoComplete="clientName"
                className="input-sm w-full rounded-md border-0 px-3 py-1.5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400"
              />
            </div>
          </div>
          <div className="flex w-full flex-1 flex-col gap-2">
            <label
              htmlFor="clientName"
              className="text-xs font-medium text-gray-900"
            >
              Practice Area
            </label>
            <div className="mt-2">
              <input
                placeholder="Search by practice area"
                type="text"
                name="practiceAreaName"
                id="practiceAreaName"
                value={practiceAreaName}
                onChange={(e) => {
                  setPracticeAreaName(e.target.value);
                }}
                autoComplete="practiceAreaName"
                className="input-sm w-full rounded-md border-0 px-3 py-1.5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400"
              />
            </div>
          </div>
        </div>
        {matters && Object.keys(matters).length > 0 && (
          <div className="mt-4 flex max-h-60 flex-col divide-y divide-gray-100 overflow-y-auto">
            {Object.values(matters).map((matter) => (
              <div
                key={matter.id}
                className="flex items-center gap-2 py-2"
                onClick={() => {
                  handleSelectMatter(matter.id);
                }}
              >
                {selectedMatters.has(matter.id) ? (
                  <MdOutlineCheckBox className="h-5 w-5 text-cyan-500" />
                ) : (
                  <MdCheckBoxOutlineBlank className="h-5 w-5 text-cyan-500" />
                )}
                <div className="flex flex-1">
                  <div className="flex w-1/2 flex-col">
                    <p className="text-sm font-semibold">{matter.name}</p>
                    <p className="text-xs text-gray-600">
                      {matter.client_name}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm">
                      {matter.flatSubmission.practice_area_name}
                    </p>
                    <div className="flex items-center gap-2 text-xs text-gray-600">
                      <p>{matter.flatSubmission.location}</p>
                      <p>-</p>
                      <p>{matter.flatSubmission.year}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Modal>
    </>
  );
};

export default SearchMatterModal;
