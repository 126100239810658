import { useQuery, useMutation } from "@tanstack/react-query";
import React from "react";

import { getSupabaseClient } from "./supabase";

import {
  type PostgrestSingleResponse,
  type User as AuthUser,
} from "@supabase/supabase-js";

import { queryClient } from "./queryClient";

import type {
  Admin,
  User,
  Firm,
  FirmByDomain,
  UrlCrawlStatus,
  ChambersCanonicalPracticeArea,
} from "@precedent/db-types/src/schema";

const useSupabase = () => {
  return React.useMemo(getSupabaseClient, []);
};

/** ** USERS ****/

// Fetch user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid: string | undefined) {
  const supabase = useSupabase();
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery({
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    queryKey: ["user", { uid }],
    // Query function that fetches data
    queryFn: async (): Promise<User[]> =>
      supabase
        .from("user")
        .select("*")
        .eq("id", uid as string)
        .then(handleResponse<User[]>),
    // Only call query function if we have a `uid`
    enabled: !!uid,
  });
}

export function useIsAdmin(uid: string | undefined) {
  const supabase = useSupabase();

  return useQuery({
    queryKey: ["isAdmin", uid as string],
    queryFn: async (): Promise<Admin[]> =>
      supabase
        .from("admin")
        .select("*")
        .eq("user_id", uid as string)
        .limit(1)
        .then(handleResponse<Admin[]>),
    enabled: !!uid,
  });
}

type UserNotAdmin = User & {
  admin: null;
};

export function useUnassignedUsers() {
  const supabase = useSupabase();

  return useQuery({
    queryKey: ["unassignedUsers"],
    queryFn: async (): Promise<UserNotAdmin[]> =>
      supabase
        .from("user")
        .select(
          `
          id,
          first_name,
          last_name,
          email,
          email_notifications_enabled,
          created_at,
          firm_id,
          admin ()
          `,
        )
        .is("admin", null)
        .is("firm_id", null)
        .returns<UserNotAdmin[]>()
        .then(handleResponse<UserNotAdmin[]>),
  });
}

export type AllUsersRecord = User & {
  law_firm: Firm | null;
  admin: Admin | null;
};

export function useAllUsers(searchTerm?: string) {
  const supabase = useSupabase();
  const query = `
    id,
    first_name,
    last_name,
    email,
    email_notifications_enabled,
    created_at,
    firm_id,
    admin ( * ),
    law_firm ( * )`;

  return useQuery({
    queryKey: ["allUsers", searchTerm],
    queryFn: async (): Promise<AllUsersRecord[]> => {
      if (searchTerm) {
        return supabase
          .from("user")
          .select(query)
          .or(
            `or(email.ilike.%${searchTerm}%), or(first_name.ilike.%${searchTerm}%), or(last_name.ilike.%${searchTerm}%)`,
          )
          .returns<AllUsersRecord[]>()
          .then(handleResponse<AllUsersRecord[]>);
      } else {
        return supabase
          .from("user")
          .select(query)
          .returns<AllUsersRecord[]>()
          .then(handleResponse<AllUsersRecord[]>);
      }
    },
  });
}

export type AllFirmsRecord = Firm & {
  user: User[] | null;
};

export function useAllFirms(searchTerm?: string) {
  const supabase = useSupabase();
  const query = `
    *,
    user ( * )`;

  return useQuery({
    queryKey: ["allFirms", searchTerm],
    queryFn: async (): Promise<AllFirmsRecord[]> => {
      if (searchTerm) {
        return supabase
          .from("law_firm")
          .select(query)
          .ilike("name", `%${searchTerm}%`)
          .returns<AllFirmsRecord[]>()
          .then(handleResponse<AllFirmsRecord[]>);
      } else {
        return supabase
          .from("law_firm")
          .select(query)
          .returns<AllFirmsRecord[]>()
          .then(handleResponse<AllFirmsRecord[]>);
      }
    },
  });
}

export function useUrls(searchTerm?: string) {
  const supabase = useSupabase();

  return useQuery({
    queryKey: ["urls", searchTerm],
    queryFn: async (): Promise<UrlCrawlStatus[]> => {
      if (searchTerm) {
        return supabase
          .from("url_crawl_status")
          .select("*")
          .ilike("url", `%${searchTerm}%`)
          .returns<UrlCrawlStatus[]>()
          .then(handleResponse<UrlCrawlStatus[]>);
      } else {
        return supabase
          .from("url_crawl_status")
          .select("*")
          .returns<UrlCrawlStatus[]>()
          .then(handleResponse<UrlCrawlStatus[]>);
      }
    },
  });
}

export function useFirmsByDomains(domains: string[], searchTerm: string) {
  const supabase = useSupabase();
  const domainsQuery = domains.map((d) => `%@${d}`);

  return useQuery({
    queryKey: ["firmsByDomains", domains, searchTerm],
    queryFn: async (): Promise<FirmByDomain[]> =>
      supabase
        .rpc("law_firms_by_domains", {
          email_domains: domainsQuery,
          search_term: searchTerm,
        })
        .then(handleResponse<FirmByDomain[]>),
  });
}

export function useAssignUsersToFirmMutation() {
  const supabase = useSupabase();

  return useMutation({
    mutationFn: async (params: {
      userIds: string[];
      firmId: string;
      onSuccess: () => void;
    }): Promise<null> => {
      const { userIds, firmId } = params;

      return supabase
        .from("user")
        .update({ firm_id: firmId })
        .in("id", userIds)
        .then(handleResponse<null>);
    },
    onSuccess: async (res, params) => {
      const { onSuccess } = params;
      await queryClient.invalidateQueries({
        queryKey: ["unassignedUsers"],
      });
      onSuccess();
    },
  });
}

export function useUnassignUsersFromFirmMutation() {
  const supabase = useSupabase();

  return useMutation({
    mutationFn: async (params: {
      userIds: readonly string[];
    }): Promise<null> => {
      const { userIds } = params;

      return supabase
        .from("user")
        .update({ firm_id: null })
        .in("id", userIds)
        .then(handleResponse<null>);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["allUsers"],
      });
    },
  });
}

export function useAddFirmMutation() {
  const supabase = useSupabase();

  return useMutation({
    mutationFn: async ({ name }: { name: string }): Promise<null> => {
      return supabase
        .from("law_firm")
        .insert({ name })
        .then(handleResponse<null>);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["allFirms"],
      });
    },
  });
}

export function useRenameFirmMutation() {
  const supabase = useSupabase();

  return useMutation({
    mutationFn: async ({
      id,
      name,
    }: {
      id: string;
      name: string;
    }): Promise<null> => {
      return supabase
        .from("law_firm")
        .update({ name })
        .eq("id", id)
        .then(handleResponse<null>);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["allFirms"],
      });
    },
  });
}

// Fetch user data (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid: string) {
  const supabase = getSupabaseClient();

  return supabase
    .from("user")
    .select()
    .eq("id", uid)
    .single()
    .then(handleResponse<User>);
}

// Update an existing user
export async function updateUser(uid: string, data: AuthUser) {
  const supabase = getSupabaseClient();

  const response = await supabase
    .from("user")
    .update(data)
    .eq("id", uid)
    .select("*")
    .then(handleResponse<User[]>);
  // Invalidate and refetch queries that could have old data
  await queryClient.invalidateQueries({
    queryKey: ["user", { uid }],
  });

  return response;
}

// Chambers

export interface ChambersScheduleRow {
  Guide: string;
  Location: string;
  "Practice Area": string;
  "Research Status Date": string;
  "Current Research Status": string;
  "Researcher Notes": string;
}

export function useUpdateOrCreateChambersSchedule() {
  const supabase = useSupabase();

  async function fetchPracticeAreaData(): Promise<Map<string, number>> {
    const practiceAreaMap = new Map<string, number>();
    let count = 0;
    let page = 0;
    const pageSize = 1000;

    do {
      const {
        data,
        error,
        count: totalCount,
      } = await supabase
        .from("chambers_canonical_practice_area")
        .select("id, guide, location, practice_area_name", { count: "exact" })
        .range(page * pageSize, (page + 1) * pageSize - 1);

      if (error) throw error;

      data.forEach((item) => {
        const key = `${item.guide}-${item.location}-${item.practice_area_name}`;
        practiceAreaMap.set(key, item.id);
      });

      count = totalCount !== null ? totalCount : 0;
      page++;
    } while (count > page * pageSize);

    return practiceAreaMap;
  }

  return async (rows: ChambersScheduleRow[]) => {
    const practiceAreaMap = await fetchPracticeAreaData();

    const batchSize = 500;
    for (let i = 0; i < rows.length; i += batchSize) {
      const batchRows = rows.slice(i, i + batchSize);

      const upsertData = batchRows
        .map((row) => {
          const {
            Guide: guide,
            Location: location,
            "Practice Area": practice_area,
            "Research Status Date": research_status_date,
            "Current Research Status": current_research_status,
            "Researcher Notes": researcher_notes,
          } = row;

          const key = `${guide}-${location}-${practice_area}`;
          const practiceAreaId = practiceAreaMap.get(key);

          if (!practiceAreaId) {
            console.error("No matching practice area found:", row);
            return null;
          }

          return {
            chambers_canonical_practice_area_id: practiceAreaId,
            year: new Date().getFullYear(),
            research_status_date,
            current_research_status,
            researcher_notes,
          };
        })
        .filter((data) => data !== null);

      const uniqueUpsertData = Array.from(
        new Map(
          upsertData.map((data) => [
            `${data!.chambers_canonical_practice_area_id}-${data!.year}`,
            data,
          ]),
        ).values(),
      );

      await supabase
        .from("chambers_schedule")
        .upsert(uniqueUpsertData, {
          onConflict: "chambers_canonical_practice_area_id, year",
          ignoreDuplicates: false,
        })
        .then(handleResponse<null>);
    }
  };
}

interface ChambersScheduleData {
  id: number;
  year: number;
  research_status_date: string;
  current_research_status: string;
  researcher_notes: string;
  chambers_canonical_practice_area: {
    id: number;
    practice_area_name: string;
    guide: string;
    location: string;
  }[];
}

export function useChambersScheduleData(page: number, rowsPerPage: number) {
  const supabase = useSupabase();

  return useQuery({
    queryKey: ["chambersScheduleData", page, rowsPerPage],
    queryFn: async (): Promise<ChambersScheduleData[]> =>
      supabase
        .from("chambers_schedule")
        .select(
          `
          id,
          year,
          research_status_date,
          current_research_status,
          researcher_notes,
          chambers_canonical_practice_area (
            id,
            practice_area_name,
            guide,
            location
          )
        `,
        )
        .order("id")
        .range(page * rowsPerPage, (page + 1) * rowsPerPage - 1)
        .then(handleResponse<ChambersScheduleData[]>),
  });
}

/** ** HELPERS ****/

// Get response data or throw error if there is one
export function handleResponse<T>(response: PostgrestSingleResponse<T>) {
  if (response.error !== null) {
    throw new Error(response.error.message);
  }
  return response.data;
}
