import router from "@/router";
import {
  appVersionAtom,
  firmIdAtom,
  firmNameAtom,
  supabaseClientAtom,
  supabaseSessionAtom,
  supabaseUrlAtom,
  supabaseUserAtom,
} from "@/supabase";
import HyperDX from "@hyperdx/browser";
import { type User } from "@precedent/db-types/src/schema";
import {
  type RealtimeChannel,
  type SupabaseClient,
  createClient,
} from "@supabase/supabase-js";
import { useQuery } from "@tanstack/react-query";
import { useAtom, useSetAtom } from "jotai";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import invariant from "tiny-invariant";

if (import.meta.env.PROD) {
  invariant(
    import.meta.env.VITE_HYPERDX_API_KEY,
    "HyperDX API key was not provided",
  );

  HyperDX.init({
    apiKey: import.meta.env.VITE_HYPERDX_API_KEY,
    service: "ask-app",
    // NOTE: tracePropagationTargets logs all outbound/inbound payload; can't use it by default
    // tracePropagationTargets: [new RegExp('/rest/v1/', 'i')], // Set to link traces from frontend to backend requests
    consoleCapture: true, // Capture console logs (default false)
    advancedNetworkCapture: false, // Capture full HTTP request/response headers and bodies (default false)
    maskAllInputs: true,
    maskAllText: true,
  });
}

invariant(
  import.meta.env.VITE_SUPABASE_PUBLIC_KEY,
  "VITE_SUPABASE_PUBLIC_KEY not provided",
);
invariant(import.meta.env.VITE_SUPABASE_URL, "VITE_SUPABASE_URL not provided");

const { VITE_SUPABASE_PUBLIC_KEY, VITE_SUPABASE_URL, VITE_APP_VERSION } =
  import.meta.env;

const appVersion = VITE_APP_VERSION ?? "000000000";

const App = () => {
  const [supabase, setSupabaseClient] = useAtom(supabaseClientAtom);
  const [user, setUser] = useAtom(supabaseUserAtom);
  const setSupabaseUrl = useSetAtom(supabaseUrlAtom);
  const setSession = useSetAtom(supabaseSessionAtom);
  const setFirmId = useSetAtom(firmIdAtom);
  const setFirmName = useSetAtom(firmNameAtom);
  const setAppVersion = useSetAtom(appVersionAtom);
  const { enqueueSnackbar } = useSnackbar();

  const supabaseClientResult = useQuery({
    queryKey: ["supabase_client"],
    queryFn: () => {
      return new Promise<SupabaseClient>((resolve) => {
        const client = createClient(
          VITE_SUPABASE_URL,
          VITE_SUPABASE_PUBLIC_KEY,
        );
        resolve(client);
      });
    },
    enabled: !supabase,
    staleTime: Infinity,
    gcTime: Infinity,
  });

  useEffect(() => {
    if (supabaseClientResult.data) {
      setSupabaseClient(supabaseClientResult.data);
      setSupabaseUrl(VITE_SUPABASE_URL);
    }
  }, [supabaseClientResult]);

  useEffect(() => {
    setAppVersion(appVersion);

    if (!supabase) {
      return;
    }

    const fetchUserData = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      if (session) {
        const { data: user, error } = await supabase
          .from("user")
          .select("*")
          .eq("id", session.user.id)
          .single();

        if (error) {
          throw new Error(error.message);
        }

        if (user) {
          setUser(user);
          setFirmId(user.firm_id || undefined);

          if (user.id) {
            HyperDX.setGlobalAttributes({
              userId: user.id,
            });

            if (user.firm_id) {
              HyperDX.setGlobalAttributes({
                firmId: user.firm_id,
              });
            }
          }

          // Only set session after firmId is set
          setSession(session);

          if (user.firm_id) {
            const { data: firm, error: firmError } = await supabase
              .from("law_firm")
              .select("*")
              .eq("id", user.firm_id)
              .single();

            if (firmError) {
              console.error(firmError);
            }

            if (firm) {
              setFirmName(firm.name);
            } else {
              setFirmName(null);
            }
          } else {
            setFirmName(null);
          }
        } else {
          await supabase.auth.signOut();
        }
      }
    };

    void fetchUserData();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN") {
        setSession(session);
        void fetchUserData();
      } else if (event === "PASSWORD_RECOVERY") {
        window.location.href = "/auth/set-password";
      } else {
        if (session) {
          void fetchUserData(); // Call fetchUserData to handle the new session
        } else {
          // Handle case when there's no session (user logged out)
          setSession(null);
          setUser(undefined);
          setFirmId(undefined);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [supabase]);

  useEffect(() => {
    let subscription: RealtimeChannel;

    const subscribeToUserChanges = () => {
      if (supabase && user) {
        subscription = supabase
          .channel("user-changes")
          .on(
            "postgres_changes",
            {
              event: "UPDATE",
              schema: "public",
              table: "user",
              filter: `id=eq.${user.id}`,
            },
            (payload) => {
              enqueueSnackbar("Profile updated successfully", {
                variant: "success",
              });

              setUser(payload.new as User);
            },
          )
          .subscribe();
      }
    };

    subscribeToUserChanges();

    return () => {
      if (subscription && supabase) {
        void supabase.removeChannel(subscription);
      }
    };
  }, [supabase, user?.id]);

  return (
    <div className="min-h-screen w-full">
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
