import { queryClient, queryKeys } from "@/client";
import { useAuth } from "@/contexts/AuthHook";
import { type SupabaseClient } from "@supabase/supabase-js";
import { useMutation } from "@tanstack/react-query";
import capitalize from "lodash/capitalize";
import { enqueueSnackbar } from "notistack";
import { twMerge } from "tailwind-merge";

type StatusType = "submission" | "matter";

const getStatusStyle = (status: string | null, type: StatusType) => {
  if (type === "submission") {
    switch (status) {
      case "Complete":
        return "text-green-700 bg-green-50 ring-green-600/20";
      case "In Progress":
        return "text-yellow-800 bg-yellow-50 ring-yellow-600/20";
      case "Awaiting Approval":
        return "text-blue-800 bg-blue-50 ring-blue-600/20";
      case "To Do":
      default:
        return "text-gray-600 bg-gray-50 ring-gray-500/10";
    }
  } else {
    switch (status) {
      case "Content Approved":
        return "text-green-700 bg-green-50 ring-green-600/20";
      case "Topic Approved":
        return "text-yellow-800 bg-yellow-50 ring-yellow-600/20";
      case "Awaiting Approval":
      default:
        return "text-gray-600 bg-gray-50 ring-gray-500/10";
    }
  }
};

const getNextStatus = (currentStatus: string | null, type: StatusType) => {
  if (type === "submission") {
    switch (currentStatus) {
      case "To Do":
        return "In Progress";
      case "In Progress":
        return "Awaiting Approval";
      case "Awaiting Approval":
        return "Complete";
      case "Complete":
        return "To Do";
      default:
        return null;
    }
  } else {
    switch (currentStatus) {
      case "Awaiting Approval":
        return "Topic Approved";
      case "Topic Approved":
        return "Content Approved";
      case "Content Approved":
        return "Awaiting Approval";
      default:
        return null;
    }
  }
};

const StatusButton = ({
  type,
  currentStatus,
  id,
  firmId,
  submissionId,
}: {
  type: StatusType;
  currentStatus: string | null;
  id: number;
  firmId?: string;
  submissionId?: string | number;
}) => {
  const { supabase } = useAuth();
  const updateStatusMutation = useMutation({
    mutationFn: async ({
      status,
      id,
    }: {
      status: string;
      id: number;
    }): Promise<null> => {
      const table =
        type === "submission" ? "chambers_submission" : "chambers_matter";
      const statusField = type === "submission" ? "status" : "approval_status";

      return await (supabase as SupabaseClient)
        .from(table)
        .update({ [statusField]: status })
        .eq("id", id)
        .then((response) => {
          if (response.error) throw new Error(response.error.message);
          return null;
        });
    },
    onSuccess: () => {
      if (firmId) {
        void queryClient.invalidateQueries({
          queryKey: queryKeys.chambersSubmissions(firmId),
        });
      }
      if (submissionId) {
        void queryClient.invalidateQueries({
          queryKey: queryKeys.chambersSubmission(submissionId.toString()),
        });
      }

      enqueueSnackbar(`${capitalize(type)} updated successfully`, {
        variant: "success",
      });
    },
  });

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const nextStatus = getNextStatus(currentStatus, type);
    if (nextStatus) {
      updateStatusMutation.mutate({ status: nextStatus, id });
    }
  };

  return (
    <button
      className={twMerge(
        getStatusStyle(currentStatus, type),
        "h-5 cursor-pointer whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset",
      )}
      onClick={handleClick}
    >
      <span
        className="cursor-pointer"
        title={`Click to advance status to ${getNextStatus(currentStatus, type)}`}
      >
        {currentStatus}
      </span>
    </button>
  );
};

export default StatusButton;
