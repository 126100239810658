import { type ChambersSubmissionFull } from "@precedent/db-types/src/schema";
import { useMemo } from "react";

import { DropTarget } from "./DropTarget";
import MatterListItem from "./MatterListItem";

const MatterListSection = ({
  matters,
  type,
  moveMatter,
  dropIndicator,
  setDropIndicator,
}: {
  matters: ChambersSubmissionFull["matters"];
  type: string;
  moveMatter: (
    dragIndex: number,
    hoverIndex: number,
    dragType: string,
    hoverType: string,
  ) => void;
  dropIndicator: { index: number; type: string } | null;
  setDropIndicator: (indicator: { index: number; type: string } | null) => void;
}) => {
  const mattersWithPlaceholder = useMemo(() => {
    const mattersList = matters.map((matter, index) => (
      <DropTarget
        key={matter.id}
        type={type}
        index={index}
        moveMatter={moveMatter}
        setDropIndicator={setDropIndicator}
        component="li"
      >
        <MatterListItem
          index={index}
          type={type}
          matter={matter}
          setDropIndicator={setDropIndicator}
        />
      </DropTarget>
    ));

    if (matters.length > 0) {
      mattersList.push(
        <DropTarget
          key={`buffer_${type}`}
          type={type}
          index={matters.length}
          moveMatter={moveMatter}
          setDropIndicator={setDropIndicator}
          component="li"
          className="w-full px-2 py-6"
        >
          <div />
        </DropTarget>,
      );
    }

    return mattersList;
  }, [dropIndicator, matters, type, moveMatter, setDropIndicator]);

  return (
    <ul role="list" className="w-full">
      {matters.length === 0 ? (
        <DropTarget
          type={type}
          moveMatter={moveMatter}
          setDropIndicator={setDropIndicator}
          className="border border-dashed border-gray-300 p-4 text-center text-gray-500"
        >
          Drag matters here to add to {type} list
        </DropTarget>
      ) : (
        mattersWithPlaceholder
      )}
    </ul>
  );
};

export default MatterListSection;
