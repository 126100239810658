import { type ReactNode } from "react";
import type React from "react";
import { useRef } from "react";
import { useDrop } from "react-dnd";

const ItemType = "Matter";

interface DropTargetProps {
  type: string;
  index?: number;
  moveMatter: (
    dragIndex: number,
    hoverIndex: number,
    dragType: string,
    hoverType: string,
  ) => void;
  setDropIndicator: (indicator: { index: number; type: string } | null) => void;
  children?: ReactNode;
  component?: React.ComponentType<any> | string;
  className?: string;
}

export const DropTarget: React.FC<DropTargetProps> = ({
  type,
  index,
  moveMatter,
  setDropIndicator,
  children,
  component: Component = "div",
  ...props
}) => {
  const ref = useRef<HTMLElement>(null);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemType,
    hover(item: { index: number; type: string }) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index !== undefined ? index : 0;
      const dragType = item.type;
      const hoverType = type;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex && dragType === hoverType) {
        return;
      }

      setDropIndicator({ index: hoverIndex, type: hoverType });
    },
    drop(item: { index: number; type: string }) {
      const dragIndex = item.index;
      const hoverIndex = index !== undefined ? index : 0;
      const dragType = item.type;
      const hoverType = type;

      if (dragIndex !== hoverIndex || dragType !== hoverType) {
        moveMatter(dragIndex, hoverIndex, dragType, hoverType);
      }
      setDropIndicator(null);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  drop(ref);

  return (
    <Component
      ref={ref}
      {...props}
      style={{
        position: "relative",
      }}
    >
      {children}
      {isOver && canDrop && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: "lightblue",
          }}
        />
      )}
    </Component>
  );
};
