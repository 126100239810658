import { azureFunctionsServer, queryClient, queryKeys } from "@/client";
import { useAuth } from "@/contexts/AuthHook";
import {
  type ChambersMatter as ChambersMatterT,
  type ChambersSubmissionFull,
} from "@precedent/db-types/src/schema";
import { handleResponse } from "@precedent/shared-util";
import { type SupabaseClient } from "@supabase/supabase-js";
import { useMutation } from "@tanstack/react-query";
import { Editor } from "@tinymce/tinymce-react";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { PiArrowsClockwiseFill, PiWarningOctagonLight } from "react-icons/pi";
import { useParams } from "react-router-dom";

const loadingIndicatorCSS = `
@keyframes loading-ball {
  0%, 100% {
    transform: translateY(10px);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    50% {
      transform: translateY(-4px);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
}
.loading-ball {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-left: 6px;
  border-radius: 9999px;
  background-color: #333;
  opacity: 0.75;
  animation: loading-ball 0.75s infinite;
}
`;

export default function ChambersMatterDescription({
  submission,
  selectedMatterId,
}: {
  submission: ChambersSubmissionFull;
  selectedMatterId: ChambersSubmissionFull["matters"][number]["id"] | null;
}) {
  const { submissionId } = useParams();
  const { supabase, session, firmId, userId, firmName } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const selectedMatter = submission.matters.find(
    (matter) => matter.id === selectedMatterId,
  );

  const editorRef = useRef<any>(null);
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  const [matterName, setMatterName] = useState(selectedMatter?.name ?? "");

  useEffect(() => {
    setMatterName(selectedMatter?.name ?? "");
  }, [selectedMatter]);

  const regenerateDescriptionMutation = useMutation({
    mutationFn: async (): Promise<ChambersMatterT | null> =>
      await (supabase as SupabaseClient)
        .from("chambers_matter")
        .update({
          generation_status: "GENERATING",
        })
        .eq("id", selectedMatterId)
        .select("*")
        .single()
        .then(handleResponse<ChambersMatterT>),
    onSuccess: async (newMatter) => {
      if (!newMatter) {
        return;
      }

      if (session !== "loading" && session !== null) {
        void azureFunctionsServer
          .auth(`Bearer ${session.access_token}`)
          .url("/chambers-matter-generate")
          .post({
            matterName: newMatter.name,
            clientName: newMatter.client_name,
            submissionId,
            userId,
            firmId,
            firmName,
            matterId: newMatter.id,
          })
          .text();
      }
    },
  });

  const updateDescriptionMutation = useMutation({
    mutationFn: async (newDescription: string): Promise<null> => {
      return await (supabase as SupabaseClient)
        .from("chambers_matter")
        .update({ matter_description: newDescription })
        .eq("id", selectedMatterId)
        .then(handleResponse<null>);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: queryKeys.chambersSubmission(submissionId as string),
      });

      enqueueSnackbar("Description updated successfully", {
        variant: "success",
      });
    },
  });

  const handleEditorChange = () => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      const content = editorRef.current.getContent();
      updateDescriptionMutation.mutate(content);
    }, 1000);
  };

  const updateMatterNameMutation = useMutation({
    mutationFn: async (newName: string): Promise<null> => {
      return await (supabase as SupabaseClient)
        .from("chambers_matter")
        .update({ name: newName })
        .eq("id", selectedMatterId)
        .then(handleResponse<null>);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: queryKeys.chambersSubmission(submissionId as string),
      });
      enqueueSnackbar("Matter name updated successfully", {
        variant: "success",
      });
    },
  });

  return (
    <>
      <div
        className="flex items-center justify-between px-0 font-bold sm:px-2"
        style={{
          paddingTop: "0.9rem",
          paddingBottom: "0.9rem",
        }}
      >
        <input
          name="non-autocomplete-input"
          value={matterName}
          onChange={(e) => {
            setMatterName(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              updateMatterNameMutation.mutate(matterName);
              (e.target as HTMLInputElement).blur();
            }
          }}
          className="bg-transparent px-2 font-bold hover:cursor-text focus:outline-1 focus:outline-gray-400"
          style={{ width: `${matterName.length}ch` }}
        />
        <button
          type="button"
          className="flex items-center justify-between gap-2 rounded-md border-none px-2.5 py-1.5 text-right text-sm font-normal text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-blue-500/10 focus:outline-none disabled:cursor-not-allowed disabled:bg-gray-300"
          title="Regenerate Description with AI"
          onClick={() => {
            regenerateDescriptionMutation.mutate();
          }}
          disabled={selectedMatter?.generation_status === "GENERATING"}
        >
          {(() => {
            if (selectedMatter?.generation_status === "GENERATING") {
              return (
                <span className="loading loading-spinner loading-xs m-0 p-0" />
              );
            } else if (selectedMatter?.generation_status === "ERROR") {
              return (
                <span className="text-red-500">
                  <PiWarningOctagonLight size={18} />
                </span>
              );
            } else {
              return <PiArrowsClockwiseFill size={18} />;
            }
          })()}
          <span>Regen with AI</span>
        </button>
      </div>
      <div className="h-full flex-grow">
        <Editor
          apiKey={import.meta.env.VITE_TINYMCE_KEY}
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={
            selectedMatter?.generation_status === "GENERATING"
              ? `<p>
                  Generating matter description...
                  <span class='loading-ball'>&nbsp;</span>
                </p>`
              : selectedMatter?.matter_description ?? ""
          }
          init={{
            height: "100%",
            placeholder: "Please fill in a description for this matter...",
            plugins:
              "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker",
            toolbar:
              "undo redo | bold italic underline strikethrough | blocks fontfamily fontsize  | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
            tinycomments_mode: "embedded",
            tinycomments_author: "Author name",
            menubar: false,
            mergetags_list: [
              { value: "First.Name", title: "First Name" },
              { value: "Email", title: "Email" },
            ],
            content_style: loadingIndicatorCSS,
            ai_request: (_request: any, respondWith: any) =>
              respondWith.string(() =>
                Promise.reject(new Error("See docs to implement AI Assistant")),
              ),
            setup: (editor) => {
              editor.on("input", handleEditorChange);
            },
          }}
        />
      </div>
    </>
  );
}
