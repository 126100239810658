import { queryKeys, scrapeServer } from "@/client";
import Select from "@/components/Select";
import { useAuth } from "@/contexts/AuthHook";
import { downloadURL } from "@/util/supabase";
import { Menu, Transition } from "@headlessui/react";
import {
  type AiTask as AiTaskT,
  type ChambersSchedule as ChambersScheduleT,
  type ChambersSubmissionFull,
  type ChambersSubmission as ChambersSubmissionT,
} from "@precedent/db-types/src/schema";
import { handleResponse } from "@precedent/shared-util";
import {
  type SupabaseClient,
  type Session as SupabaseSession,
} from "@supabase/supabase-js";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Fragment, useEffect, useState } from "react";
import { HiCalendar, HiChevronDown, HiMapPin } from "react-icons/hi2";
import {
  PiMicrosoftWordLogoLight,
  PiWarningOctagonLight,
} from "react-icons/pi";
import { useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import StatusButton from "./StatusButton";

type PreviousSubmission = {
  id: ChambersSubmissionT["id"];
  schedule: {
    year: ChambersScheduleT["year"];
  };
};

export default function ChambersDetailHeader({
  submission,
}: {
  submission: ChambersSubmissionFull;
}) {
  const { session, supabase, userId, firmId } = useAuth();

  if (!supabase || !session || session === "loading" || !userId || !firmId) {
    return null;
  }

  return (
    <ChambersDetailHeaderInternal
      submission={submission}
      session={session}
      supabase={supabase}
      userId={userId}
      firmId={firmId}
    />
  );
}

const ChambersDetailHeaderInternal = ({
  submission,
  session,
  supabase,
  userId,
  firmId,
}: {
  submission: ChambersSubmissionFull;
  session: SupabaseSession;
  supabase: SupabaseClient;
  userId: string;
  firmId: string;
}) => {
  const { submissionId } = useParams() as { submissionId: string };

  const navigate = useNavigate();

  const [chambersExportInProgress, setChambersExportInProgress] =
    useState(false);
  const [l500ExportInProgress, setL500ExportInProgress] = useState(false);
  const [chambersExportError, setChambersExportError] = useState<
    false | string
  >(false);
  const [l500ExportError, setL500ExportError] = useState<false | string>(false);

  const { data: previousSubmissions } = useQuery({
    queryKey: queryKeys.previousSubmissions(submissionId),
    queryFn: async (): Promise<PreviousSubmission[]> =>
      await supabase
        .from("chambers_submission")
        .select(
          `
          schedule: chambers_schedule_id!inner (
            year
          ),
          id
        `,
        )
        .eq(
          "schedule.chambers_canonical_practice_area_id",
          submission.canonicalId,
        )
        .returns<PreviousSubmission[]>()
        .then(handleResponse<PreviousSubmission[]>),
    enabled: !!submissionId && !!supabase,
    refetchOnWindowFocus: false,
    staleTime: 0,
    initialData: [],
  });

  useEffect(() => {
    supabase
      .channel("export-submission-status")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "ai_task",
          filter: `input=eq.${submissionId}`,
        },
        (payload) => {
          const row = payload.new as AiTaskT;

          if (row.status === "CHAMBERS_COMPLETE" && row.output) {
            void downloadURL(
              supabase,
              row.output,
              `${submission.name}_${submission.year}_${submission.canonicalId}_briefly_chambers_export.docx`,
            );
            setChambersExportInProgress(false);
            setChambersExportError(false);
          } else if (row.status === "L500_COMPLETE" && row.output) {
            void downloadURL(
              supabase,
              row.output,
              `${submission.name}_${submission.year}_${submission.canonicalId}_briefly_l500_export.docx`,
            );
            setL500ExportInProgress(false);
            setL500ExportError(false);
          } else if (row.status === "GENERATING_CHAMBERS") {
            setChambersExportError(false);
            setChambersExportInProgress(true);
          } else if (row.status === "GENERATING_L500") {
            setL500ExportError(false);
            setL500ExportInProgress(true);
          } else if (row.status === "CHAMBERS_ERROR" && row.output) {
            setChambersExportInProgress(false);
            setChambersExportError(row.output);
          } else if (row.status === "L500_ERROR" && row.output) {
            setL500ExportInProgress(false);
            setL500ExportError(row.output);
          }
        },
      )
      .subscribe();
  }, [supabase]);

  const exportToChambersDocxMutation = useMutation({
    mutationFn: async (): Promise<{ id: string }> =>
      await supabase
        .from("ai_task")
        .insert({
          type: "CHAMBERS_SUBMISSION_EXPORT",
          status: "GENERATING_CHAMBERS",
          input: submissionId,
          created_by: userId,
          processing_started_at: "now()",
        })
        .select("id")
        .single()
        .then(handleResponse<{ id: string }>),
    onSuccess: async ({ id: aiTaskId }) => {
      await scrapeServer
        .auth(`Bearer ${session.access_token}`)
        .url("/export-chambers-submission")
        .post({ aiTaskId, submissionId })
        .text();
    },
  });

  const exportToL500DocxMutation = useMutation({
    mutationFn: async (): Promise<{ id: string }> =>
      await supabase
        .from("ai_task")
        .insert({
          type: "CHAMBERS_SUBMISSION_EXPORT",
          status: "GENERATING_L500",
          input: submissionId,
          created_by: userId,
          processing_started_at: "now()",
        })
        .select("id")
        .single()
        .then(handleResponse<{ id: string }>),
    onSuccess: async ({ id: aiTaskId }) => {
      await scrapeServer
        .auth(`Bearer ${session.access_token}`)
        .url("/export-l500-submission")
        .post({ aiTaskId, submissionId })
        .text();
    },
  });

  return (
    <div className="flex flex-col items-start gap-6 md:flex-row md:items-center md:justify-between">
      <div className="flex-1">
        <div className="flex items-center gap-4">
          <h3 className="text-2xl font-bold">
            {submission.name} - {submission.year}
          </h3>
          <StatusButton
            type="submission"
            currentStatus={submission.submissionStatus}
            id={submission.submissionId}
            firmId={firmId}
            submissionId={submission.submissionId}
          />
        </div>
        <div className="mt-1 flex flex-row flex-wrap gap-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <HiMapPin
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />

            {submission.region}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <HiCalendar
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />

            {submission.researchStatus === "Accepting Submissions"
              ? submission.due?.replace("until ", "Due ")
              : submission.researchStatus}
          </div>
        </div>
      </div>

      <div className="flex gap-3">
        <Select
          className="w-24"
          options={previousSubmissions.map((sub) => sub.schedule.year)}
          value={submission.year}
          onChange={(newValue) => {
            const subId = previousSubmissions.find(
              (sub) => sub.schedule.year === newValue,
            )?.id;
            if (subId) {
              navigate(`/chambers/${subId}`);
            }
          }}
        />

        <button
          type="button"
          className="flex items-center gap-1.5 rounded-md border-none px-2.5 py-1.5 text-left text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-blue-500/10 focus:outline-none"
          title="Export to Word"
          onClick={() => {
            exportToChambersDocxMutation.mutate();
          }}
        >
          {(() => {
            if (chambersExportInProgress) {
              return (
                <span className="loading loading-spinner loading-xs m-0 p-0" />
              );
            } else if (chambersExportError) {
              return (
                <span className="text-red-500" title={chambersExportError}>
                  <PiWarningOctagonLight size={18} />
                </span>
              );
            } else {
              return <PiMicrosoftWordLogoLight size={18} />;
            }
          })()}
          <span>Export (Chambers)</span>
        </button>

        <button
          type="button"
          className="flex items-center gap-1.5 rounded-md border-none px-2.5 py-1.5 text-left text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-blue-500/10 focus:outline-none"
          title="Export to Word"
          onClick={() => {
            exportToL500DocxMutation.mutate();
          }}
        >
          {(() => {
            if (l500ExportInProgress) {
              return (
                <span className="loading loading-spinner loading-xs m-0 p-0" />
              );
            } else if (l500ExportError) {
              return (
                <span className="text-red-500" title={l500ExportError}>
                  <PiWarningOctagonLight size={18} />
                </span>
              );
            } else {
              return <PiMicrosoftWordLogoLight size={18} />;
            }
          })()}
          <span>Export (L500)</span>
        </button>

        {/* Dropdown */}
        <Menu as="div" className="relative sm:hidden">
          <Menu.Button className="flex rounded-md border-none bg-white px-2.5 py-1.5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none">
            More
            <HiChevronDown
              className="-mr-1 ml-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={twMerge(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700",
                    )}
                  >
                    Edit
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={twMerge(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700",
                    )}
                  >
                    View
                  </a>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};
