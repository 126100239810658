import { type ChambersSubmissionFull } from "@precedent/db-types/src/schema";
import { useCallback, useState } from "react";

import MatterListSection from "./MatterListSection";
import NewMatterModal from "./NewMatterModal";
import SearchMatterModal from "./SearchMatterModal";

const MatterListTab = ({
  includedMatters,
  potentialMatters,
  setMatters,
  updateMatterOrderMutation,
  publicTab,
  ...rest
}: {
  includedMatters: ChambersSubmissionFull["matters"];
  potentialMatters: ChambersSubmissionFull["matters"];
  setMatters: React.Dispatch<
    React.SetStateAction<ChambersSubmissionFull["matters"]>
  >;
  updateMatterOrderMutation: (
    updatedMatters: ChambersSubmissionFull["matters"],
  ) => void;
  publicTab: boolean;
  dropIndicator: { index: number; type: string } | null;
  setDropIndicator: (indicator: { index: number; type: string } | null) => void;
}) => {
  const [newMatterModalOpen, setNewMatterModalOpen] = useState(false);
  const [searchMatterModalOpen, setSearchMatterModalOpen] = useState(false);

  const moveMatter = useCallback(
    (
      dragIndex: number,
      hoverIndex: number,
      dragType: string,
      hoverType: string,
    ) => {
      let updatedIncludedMatters = [...includedMatters];
      let updatedPotentialMatters = [...potentialMatters];

      if (dragType !== hoverType) {
        if (hoverType === "included") {
          const [movedMatter] = updatedPotentialMatters.splice(dragIndex, 1);
          movedMatter.included_order = hoverIndex;
          movedMatter.potential_order = null;
          updatedIncludedMatters.splice(hoverIndex, 0, movedMatter);
        } else {
          const [movedMatter] = updatedIncludedMatters.splice(dragIndex, 1);
          movedMatter.potential_order = hoverIndex;
          movedMatter.included_order = null;
          updatedPotentialMatters.splice(hoverIndex, 0, movedMatter);
        }
      } else {
        if (hoverType === "included") {
          const [movedMatter] = updatedIncludedMatters.splice(dragIndex, 1);
          updatedIncludedMatters.splice(hoverIndex, 0, movedMatter);
        } else {
          const [movedMatter] = updatedPotentialMatters.splice(dragIndex, 1);
          updatedPotentialMatters.splice(hoverIndex, 0, movedMatter);
        }
      }

      updatedIncludedMatters = updatedIncludedMatters.map((matter, index) => ({
        ...matter,
        included_order: index + 1,
        potential_order: null,
      }));

      updatedPotentialMatters = updatedPotentialMatters.map(
        (matter, index) => ({
          ...matter,
          potential_order: index + 1,
          included_order: null,
        }),
      );

      setMatters([...updatedIncludedMatters, ...updatedPotentialMatters]);
      updateMatterOrderMutation([
        ...updatedIncludedMatters,
        ...updatedPotentialMatters,
      ]);
    },
    [includedMatters, potentialMatters, setMatters, updateMatterOrderMutation],
  );

  return (
    <div className="mb-6">
      <div className="mt-4 flex items-center justify-between">
        <h2 className="text-xl font-bold tracking-tight text-gray-900">
          Included
        </h2>
        <SearchMatterModal
          open={searchMatterModalOpen}
          setModalOpen={setSearchMatterModalOpen}
          isPublic={publicTab}
          order={includedMatters.length + 1}
        />
        <NewMatterModal
          open={newMatterModalOpen}
          setModalOpen={setNewMatterModalOpen}
          isPublic={publicTab}
          order={includedMatters.length + 1}
        />
      </div>
      <MatterListSection
        matters={includedMatters}
        type="included"
        moveMatter={moveMatter}
        {...rest}
      />
      <h2 className="mt-4 text-xl font-bold tracking-tight text-gray-900">
        Potential
      </h2>
      <MatterListSection
        matters={potentialMatters}
        type="potential"
        moveMatter={moveMatter}
        {...rest}
      />
    </div>
  );
};

export default MatterListTab;
