import { useAuth } from "@/contexts/AuthHook";
import { type SupabaseClient } from "@supabase/supabase-js";
import { useEffect, useState } from "react";

const SecureAvatar = ({
  filePath,
  alt,
  className,
  size = 32,
}: {
  filePath: string;
  alt: string;
  className?: string;
  size?: number;
}) => {
  const [signedUrl, setSignedUrl] = useState<string | null>(null);
  const { supabase } = useAuth();

  useEffect(() => {
    const getSignedUrl = async () => {
      try {
        const { data } = await (supabase as SupabaseClient).storage
          .from("uploads")
          .createSignedUrl(filePath, 3600); // 1 hour expiration

        if (data?.signedUrl) {
          setSignedUrl(data.signedUrl);
        }
      } catch (error) {
        console.error("Error creating signed URL:", error);
      }
    };

    void getSignedUrl();

    // Refresh the signed URL every 50 minutes
    const intervalId = setInterval(
      () => {
        void getSignedUrl();
      },
      50 * 60 * 1000,
    );

    return () => {
      clearInterval(intervalId);
    };
  }, [filePath, supabase]);

  if (!signedUrl) {
    return null; // Or a placeholder image
  }

  return (
    <img
      src={signedUrl}
      alt={alt}
      className={className}
      style={{ width: `${size}px`, height: `${size}px`, objectFit: "cover" }}
    />
  );
};

export default SecureAvatar;
