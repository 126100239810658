import { queryClient, queryKeys } from "@/client";
import ChambersDetailHeader from "@/components/Chambers/Header";
import ChambersDetailMatterDescription from "@/components/Chambers/MatterDescription";
import ChambersDetailMatterList from "@/components/Chambers/MatterList";
import UpdateMatterForm from "@/components/Chambers/UpdateMatterForm";
import Spinner from "@/components/Spinner";
import { useAuth } from "@/contexts/AuthHook";
import {
  type ChambersMatter as ChambersMatterT,
  type ChambersReturn,
  type ChambersSubmissionFull,
} from "@precedent/db-types/src/schema";
import { type SupabaseClient } from "@supabase/supabase-js";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function ChambersDetailView() {
  const { submissionId, matterId } = useParams();
  const navigate = useNavigate();
  const { supabase } = useAuth();
  const [error, setError] = useState(false);
  const [selectedMatterId, setSelectedMatterId] = useState<
    ChambersSubmissionFull["matters"][number]["id"] | null
  >(null);

  const { data: chambersSubmission, isLoading: isLoadingSubmission } = useQuery(
    {
      queryKey: queryKeys.chambersSubmission(submissionId as string),
      queryFn: async (): Promise<ChambersSubmissionFull> => {
        const { data, error } = await (supabase as SupabaseClient)
          .from("chambers_submission")
          .select(
            `
            submissionId: id,
            submissionStatus: status,
            schedule: chambers_schedule_id (
              canonical: chambers_canonical_practice_area_id (
                name: practice_area_name,
                region: location,
                canonicalId: id
              ),
              year,
              researchStatus: current_research_status,
              due: research_status_date
            ),
            matters: chambers_matter!chambers_submission_id (
              id,
              approval_status,
              client_name,
              name,
              included_order,
              potential_order,
              is_public,
              matter_description,
              value,
              lead_partner_name,
              other_team_members,
              other_firms,
              date_of_completion,
              other_info,
              cross_border,
              generation_status
            )
          `,
          )
          .eq("id", submissionId)
          .returns<ChambersReturn[]>()
          .single();

        if (error) {
          setError(true);
          throw new Error(error.message);
        }

        // Transform the data to fit the ChambersSubmission type
        const { canonical, ...rest } = data.schedule;
        const transformedData = {
          submissionId: data.submissionId,
          submissionStatus: data.submissionStatus,
          ...canonical,
          ...rest,
          approvals: data.matters.map((matter) => matter.approval_status),
          matters: data.matters,
        };

        return transformedData;
      },
      enabled: !!submissionId && !!supabase,
      refetchOnWindowFocus: false,
      staleTime: 0,
    },
  );

  useEffect(() => {
    const subscribe = () => {
      if (supabase) {
        supabase
          .channel("matters-changes")
          .on(
            "postgres_changes",
            {
              event: "*",
              schema: "public",
              table: "chambers_matter",
              filter: `chambers_submission_id=eq.${submissionId}`,
            },
            (payload) => {
              const newMatterPayload = payload.new as ChambersMatterT;
              if (payload.eventType === "UPDATE") {
                queryClient.setQueryData(
                  queryKeys.chambersSubmission(submissionId as string),
                  (oldData: ChambersSubmissionFull | undefined) => {
                    if (!oldData) return oldData;
                    return {
                      ...oldData,
                      matters: oldData.matters.map((matter) =>
                        matter.id === newMatterPayload.id
                          ? {
                              ...matter,
                              matter_description:
                                newMatterPayload.matter_description,
                              generation_status:
                                newMatterPayload.generation_status,
                            }
                          : matter,
                      ),
                    };
                  },
                );
              }
            },
          )
          .subscribe();
      }
    };

    // We add the visibility code because when the tab loses focus
    // for long periods of time, the supabase subscription is lost
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        subscribe();
      }
    };

    subscribe();
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [submissionId, supabase, chambersSubmission]);

  useEffect(() => {
    const currentMatterId = matterId ? parseInt(matterId) : null;

    if (currentMatterId !== selectedMatterId) {
      // URL matterId and selectedMatterId are out of sync
      if (matterId) {
        // URL has a matterId, update selectedMatterId
        setSelectedMatterId(currentMatterId);
      } else {
        // URL doesn't have a matterId, but selectedMatterId is set
        // This likely means a matter was just deleted
        setSelectedMatterId(null);
      }
    } else if (selectedMatterId && !matterId) {
      // selectedMatterId is set, but URL doesn't have a matterId
      // Update URL to include the matterId
      navigate(`/chambers/${submissionId}/matter/${selectedMatterId}`);
    } else if (!selectedMatterId && matterId) {
      // selectedMatterId is null, but URL has a matterId
      // Update URL to remove the matterId
      navigate(`/chambers/${submissionId}`);
    }
  }, [submissionId, matterId, selectedMatterId, navigate]);

  return error ? (
    <div className="flex h-full flex-col items-center justify-center gap-2">
      <h2 className="text-2xl font-bold">Error loading submission</h2>
      <Link to="/chambers" className="text-blue-600 underline">
        Click here to return to submission list
      </Link>
    </div>
  ) : isLoadingSubmission || !chambersSubmission ? (
    <Spinner />
  ) : (
    <div className="mx-10 my-12 flex max-h-full min-h-0 flex-col gap-6">
      <ChambersDetailHeader submission={chambersSubmission} />

      <div className="flex h-full min-h-0 w-full items-start gap-x-4">
        <aside className="h-full w-96 overflow-y-auto pr-4">
          <ChambersDetailMatterList submission={chambersSubmission} />
        </aside>

        {selectedMatterId ? (
          <>
            <main className="flex h-full flex-1 flex-col overflow-y-auto pr-4">
              <ChambersDetailMatterDescription
                submission={chambersSubmission}
                selectedMatterId={selectedMatterId}
              />
            </main>

            <aside className="h-full w-80 overflow-y-auto">
              <UpdateMatterForm
                submission={chambersSubmission}
                selectedMatterId={selectedMatterId}
              />
            </aside>
          </>
        ) : (
          <div className="flex flex-1 items-center justify-center px-6 pt-32 text-4xl font-bold text-gray-400">
            <span>Select a matter from the list to see details</span>
          </div>
        )}
      </div>
    </div>
  );
}
