import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { HiCheck, HiChevronUpDown } from "react-icons/hi2";
import { twMerge } from "tailwind-merge";

const Select = ({
  label,
  options,
  value,
  onChange,
  multiple = false,
  className,
}: {
  label?: string;
  options: any[];
  value: any[] | any;
  onChange: (newValues: any[] | any) => void;
  multiple?: boolean;
  className?: string;
}) => (
  <Listbox value={value} onChange={onChange} multiple={multiple}>
    {({ open }) => (
      <div className={twMerge(className, "flex flex-col gap-3")}>
        {label && (
          <Listbox.Label className="text-sm font-medium text-gray-900">
            {label}
          </Listbox.Label>
        )}
        <div className="relative">
          <Listbox.Button className="relative w-full rounded-md border-0 px-3 py-1.5 text-left text-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400">
            <span className="block truncate">
              {Array.isArray(value) ? value.join(", ") : value}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <HiChevronUpDown
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((year) => (
                <Listbox.Option
                  key={year}
                  className={({ active }) =>
                    twMerge(
                      active ? "bg-blue-100" : "bg-white",
                      "relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900",
                    )
                  }
                  value={year}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={twMerge(
                          selected ? "font-semibold" : "font-normal",
                          "block truncate",
                        )}
                      >
                        {year}
                      </span>

                      {selected ? (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                          <HiCheck className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </div>
    )}
  </Listbox>
);

export default Select;
