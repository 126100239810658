import {
  firmIdAtom,
  supabaseClientAtom,
  supabaseSessionAtom,
} from "@/supabase";
import type { User } from "@precedent/db-types/src/schema";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

import Spinner from "./components/Spinner";

const WithAuth = ({ children }: { children: React.ReactNode }) => {
  const supabase = useAtomValue(supabaseClientAtom);
  const session = useAtomValue(supabaseSessionAtom);
  const setFirmId = useSetAtom(firmIdAtom);

  useEffect(() => {
    if (supabase) {
      const channel = supabase
        .channel("user-changes")
        .on(
          // @ts-expect-error: No overload matches this call
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "user",
          },
          (payload: { new: User }) => {
            if (payload.new.firm_id) {
              setFirmId(payload.new.firm_id);
            } else {
              setFirmId(undefined);
            }
          },
        )
        .subscribe();

      return () => {
        void channel.unsubscribe();
      };
    }
  }, [supabase]);

  if (session !== "loading") {
    if (session) {
      return children;
    } else {
      return <Navigate to="/auth/sign-in" />;
    }
  } else {
    return <Spinner />;
  }
};

export default WithAuth;
